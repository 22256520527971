<template>
  <button @click="toggleTheme">
    <!-- Display the appropriate text and icon based on the current theme -->
    <i>Theme: {{ currentTheme === 'light' ? '🌙 Dark Mode' : '☀️ Light Mode' }}</i>
  </button>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue'
import { useThemeStore } from '@/stores/themeStore'

export default defineComponent({
  name: 'ThemeToggleButton',
  setup() {
    const themeStore = useThemeStore()

    // Computed property to reactively get the current theme from the store
    const currentTheme = computed(() => themeStore.theme)

    return {
      currentTheme,
      toggleTheme: themeStore.toggleTheme
    }
  }
})
</script>

<style scoped>
button {
  width: 100%;
  border: none;
}
i {
  font-size: 15px;
}
</style>
