<template>
  <h2>Product Search Filters</h2>
  <hr />
  <div class="sidebar-searcher">
    <h3>Price</h3>
    <div class="price-container border">
      <div class="price-filter">
        <label for="min-price">Min:</label>
        <br />
        R$ <input type="number" v-model="minPrice" id="min-price" placeholder="0" />
      </div>
      <div class="price-filter">
        <label for="max-price">Max:</label>
        <br />
        R$ <input type="number" v-model="maxPrice" id="max-price" placeholder="0" />
      </div>
    </div>
    <h3>Category</h3>
    <div class="category-container border">
      <div class="category-item border">
        <label class="checkbox-container" for="cat1">
          mamacita
          <input type="checkbox" id="cat1" name="category1" value="categ1" checked="true" />
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="category-item border">
        <label class="checkbox-container" for="cat2">
          ty
          <input type="checkbox" id="cat2" name="category1" value="categ1" checked="true" />
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="category-item border">
        <label class="checkbox-container" for="cat3">
          paralelepipedo
          <input type="checkbox" id="cat3" name="category1" value="categ1" checked="true" />
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="category-item border">
        <label class="checkbox-container" for="cat4">
          kibe
          <input type="checkbox" id="cat4" name="cat4" value="cat4" checked="true" />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <h3>Subcategory</h3>
    <div class="category-container border"></div>
    <br />
    <button id="add-filter-btn" @click="applyPriceFilter">Apply Filter</button>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRouter, useRoute } from 'vue-router'

export default defineComponent({
  name: 'SidebarFilter',
  setup() {
    const minPrice = ref<number | null>(null)
    const maxPrice = ref<number | null>(null)
    const router = useRouter()
    const route = useRoute()

    const applyPriceFilter = () => {
      // Retain existing query parameters and add new ones
      const query = {
        ...route.query, // Keep existing query parameters
        ...(minPrice.value !== null &&
          !isNaN(minPrice.value) && { minPrice: minPrice.value.toString() }),
        ...(maxPrice.value !== null &&
          !isNaN(maxPrice.value) && { maxPrice: maxPrice.value.toString() })
      }

      router.push({
        name: 'search-results',
        query
      })

      console.log(
        'applyPriceFilter',
        '\nminPrice: ',
        minPrice.value,
        '\nmaxPrice: ',
        maxPrice.value
      )
    }

    return {
      minPrice,
      maxPrice,
      applyPriceFilter
    }
  }
})
</script>

<style scoped>
#add-filter-btn {
  padding: 8px 12px;
  background-color: blue;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

.price-container {
  display: flex;
  padding: 0.5rem;
}

.price-filter {
  margin-bottom: 8px;
}

.price-filter input {
  height: 34px;
  border-radius: 5px;
  width: 80%;
  padding: 5px;
}
</style>
