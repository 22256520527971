import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'

import App from './App.vue'
import router from './router'

import '@fortawesome/fontawesome-free/css/all.min.css'
import { useThemeStore } from './stores/themeStore'

import './firebase'

// Only include MSW in development
if (import.meta.env.MODE === 'development') {
  import('./mocks/browser')
    .then(({ worker }) => {
      worker.start()
    })
    .catch((err) => {
      console.error('Failed to load MSW:', err)
    })
}

const app = createApp(App)

app.use(createPinia())
app.use(router)

const themeStore = useThemeStore()
themeStore.loadTheme()

app.mount('#app')
