import axios from 'axios'
import { auth } from '@/firebase/index'
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
  GoogleAuthProvider,
  signInWithPopup,
  fetchSignInMethodsForEmail,
  signOut
} from 'firebase/auth'
import { useUserStore } from '@/stores/lugand-api/userStore'
import Cookies from 'js-cookie'

// Configuração do cliente Axios para a API DRF
const apiClient = axios.create({
  baseURL: 'http://localhost:8000/api',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

// Interceptor para adicionar o JWT em todas as requisicoes
apiClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('accessToken') || Cookies.get('accessToken')
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Função para registrar usuário na API DRF
const registerUserInAPI = async (email: string, password: string, additionalData: object) => {
  const response = await apiClient.post(`/register/`, {
    email,
    password,
    additional_data: additionalData
  })
  return response.data
}

// Função para obter dados do usuário da API DRF
const getUserDataFromAPI = async (email: string) => {
  const response = await apiClient.get(`/user/${email}/`)
  return response.data
}

// Funcao para obter dados do usuario da Api DRF
const getDataFromApi = async (email: string) => {
  const response = await apiClient.get(`/user/${email}/`)
  return response.data
}

// Funcao para armazenar tokens JWT
const storeTokens = (accessToken: string, refreshToken: string) => {
  localStorage.setItem('accessToken', accessToken)
  localStorage.setItem('refreshToken', refreshToken)
}

// Funcao para remover tokens JWT
const clearTokens = () => {
  localStorage.removeItem('accessToken')
  localStorage.removeItem('refreshToken')
}

// Registro direto pelo site
export const register = async (email: string, password: string) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password)
    await sendEmailVerification(userCredential.user)

    // Registrar usuário na Api DRF
    const { access, refresh } = await registerUserInAPI(email, password, {
      display_name: userCredential.user.displayName ?? ''
    })

    // Armazenar tokens JWT
    storeTokens(access, refresh)

    return userCredential.user
  } catch (error) {
    console.error('Registration failed:', error)
    throw error
  }
}

// Login direto pelo site
export const login = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    if (!userCredential.user.emailVerified) {
      throw new Error('Email not verified')
    }

    //Chamar a API Django para obter os tokens JWT
    const { access, refresh } = await apiClient
      .post('/login/', { email, password })
      .then((res) => res.data)

    // Armazenar tokens JWT
    storeTokens(access, refresh)

    // Obter dados do usuário da API DRF
    const userData = await getUserDataFromAPI(email)
    const userStore = useUserStore()
    userStore.setUser({
      name: userData.display_name,
      photoURL: userCredential.user.photoURL ?? '',
      email: userCredential.user.email ?? ''
    })

    return userCredential.user
  } catch (error) {
    console.error('Login failed:', error)
    throw error
  }
}

// Reset de senha direto pelo site
export const resetPassword = async (email: string) => {
  try {
    await sendPasswordResetEmail(auth, email)
    console.log('Password reset email sent')
  } catch (error) {
    console.error('Password reset failed:', error)
    throw error
  }
}

// Função para login com Google
export const loginWithGoogle = async () => {
  const provider = new GoogleAuthProvider()
  const userStore = useUserStore()
  try {
    const result = await signInWithPopup(auth, provider)
    const user = result.user

    // Definir o usuário na store
    userStore.setUser({
      name: user.displayName ?? '',
      photoURL: user.photoURL ?? '',
      email: user.email ?? ''
    })

    // Verificar se o email já está registrado
    const signInMethods = await fetchSignInMethodsForEmail(auth, user.email as string)
    if (signInMethods.length === 0) {
      // Registrar usuario na API DRF
      const { access, refresh } = await registerUserInAPI(user.email as string, 'defaultPassword', {
        display_name: user.displayName ?? ''
      })
      // Armazenar tokens JWT
      storeTokens(access, refresh)

      return { user, isNewUser: true }
    } else {
      // Obter dados do usuário da API DRF
      const userData = await getUserDataFromAPI(user.email as string)
      userStore.setUser({
        name: userData.display_name,
        photoURL: user.photoURL ?? '',
        email: user.email ?? ''
      })
      return { user, isNewUser: false }
    }
  } catch (error) {
    console.error('Google login failed:', error)
    throw error
  }
}

// Logout geral
export const logout = async () => {
  const userStore = useUserStore()
  try {
    // lout no firebase
    await signOut(auth)
    // limpa estado local do usuário
    userStore.clearUser()
    // Limpar tokens JWT
    clearTokens()
    console.log('Logout successful from both Firebase and Django')
  } catch (error) {
    console.error('Logout failed:', error)
    throw error
  }
}

export { auth }
