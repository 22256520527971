import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useUserStore = defineStore('user', () => {
  const user = ref<{ name: string; photoURL: string; email: string } | null>(null)

  const setUser = (userData: { name: string; photoURL: string; email: string }) => {
    user.value = userData
  }

  const clearUser = () => {
    user.value = null
  }

  return { user, setUser, clearUser }
})
