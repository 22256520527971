<template>
  <Swiper class="header-carousel" autoplay :loop="true" pagination navigation>
    <SwiperSlide
      v-for="(header, index) in headers"
      :key="index"
      :style="{ backgroundImage: `url(${header.image})` }"
    >
      <div class="header-content border">
        <h1 class="header-title">{{ header.title }}</h1>
        <p class="header-subtitle">{{ header.subtitle }}</p>
      </div>
    </SwiperSlide>
  </Swiper>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper-bundle.css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import HeaderImage from '@/assets/images/headers/header_img.webp'
import HeaderImage2 from '@/assets/images/headers/header_img2.jpg'
import HeaderImage3 from '@/assets/images/headers/header_img3.webp'

export default defineComponent({
  name: 'HeaderCarousel',
  components: {
    Swiper,
    SwiperSlide
  },
  setup() {
    const headers = [
      {
        image: HeaderImage2,
        title: 'Seu Texto Aqui 1',
        subtitle: 'Subtítulo Aqui 1'
      },
      {
        image: HeaderImage3,
        title: 'Seu Texto Aqui 2',
        subtitle: 'Subtítulo Aqui 2'
      },
      {
        image: HeaderImage,
        title: 'Seu Texto Aqui 3',
        subtitle: 'Subtítulo Aqui 3'
      }
    ]
    console.log('Header.vue Mock: ', headers)
    return {
      headers
    }
  }
})
</script>

<style scoped>
.header-carousel {
  width: 100%;
  height: 40vh;
}

.swiper-slide {
  background-size: cover;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.header-content {
  text-align: center;
  color: var(--color-text);
  background-color: var(--color-background);
  padding: 20px;
  z-index: 2;
}

.swiper-slide::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.header-title {
  font-size: 2.5rem;
  margin: 0;
}

.header-subtitle {
  font-size: 1.5rem;
  margin: 0;
}

@media (max-width: 768px) {
  .header-title {
    font-size: 2rem;
  }

  .header-subtitle {
    font-size: 1.25rem;
  }
}
</style>
