import axios from 'axios'
import { type Product } from '@/services/lugand-api-services/productService'

const BASE_URL = 'http://localhost:3000'

export interface CartItem {
  product_id: number
  quantity: number
}

export interface Cart {
  id: number
  name: string
  items: CartItem[]
}

export interface FullCartItem {
  product: Product
  quantity: number
}

export interface FullCart {
  id: number
  name: string
  items: FullCartItem[]
}

// Fetch all products from the JSON server
const fetchProducts = async (): Promise<Product[]> => {
  const response = await axios.get<Product[]>(`${BASE_URL}/products`)
  return response.data
}

// Fetch all cart items from the JSON server
const fetchCartItems = async (): Promise<Cart[]> => {
  const response = await axios.get<Cart[]>(`${BASE_URL}/cartItems`)
  return response.data
}

// Map CartDB to Cart with actual Product data
const mapCartItems = (products: Product[], cartItems: Cart[]): FullCart[] => {
  return cartItems.map((cart) => {
    const items = cart.items.map((cartItem) => {
      const product = products.find((p) => p.id === cartItem.product_id)
      if (!product) {
        throw new Error(`Product with ID ${cartItem.product_id} not found`)
      }
      return {
        product,
        quantity: cartItem.quantity
      }
    })

    return {
      id: cart.id,
      name: cart.name,
      items
    }
  })
}

// Fetch and map all carts
export const getAllCarts = async (): Promise<FullCart[]> => {
  try {
    const [products, cartItems] = await Promise.all([fetchProducts(), fetchCartItems()])
    return mapCartItems(products, cartItems)
  } catch (error) {
    console.error('Error fetching carts:', error)
    throw error
  }
}

// Fetch cart by ID
export const getCartById = async (id: number): Promise<FullCart | null> => {
  try {
    const products = await fetchProducts()
    const cartItems = await fetchCartItems()
    const cart = cartItems.find((cart) => cart.id === id)
    if (!cart) return null
    return mapCartItems(products, [cart])[0]
  } catch (error) {
    console.error('Error fetching cart by ID:', error)
    throw error
  }
}
