<template>
  <div class="cart-item">
    <ProductCard :product="item.product" />
    <div class="quantity">Quantity: {{ item.quantity }}</div>
    <div class="total-price">
      Total: {{ calculateTotal(Number(item.product.price.valor_a_vista), item.quantity) }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'
import { type FullCartItem } from '@/stores/development/cartStore' // Correctly import FullCartItem
import ProductCard from '@/components/ProductCard.vue'

export default defineComponent({
  name: 'CartItem',
  components: { ProductCard },
  props: {
    item: {
      type: Object as PropType<FullCartItem>,
      required: true
    }
  },

  methods: {
    calculateTotal(price: number, quantity: number): string {
      return `R$ ${(price * quantity).toFixed(2)}`
    }
  }
})
</script>

<style scoped>
.cart-item {
  border: 1px solid var(--color-border);
  border-radius: 5px;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  flex-direction: column;
}

.quantity {
  font-size: 1.1em;
}

.total-price {
  margin-top: 5px;
  font-weight: bold;
}
</style>
