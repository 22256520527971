<template>
  <svg
    width="30px"
    height="30px"
    viewBox="0 0 32 32"
    enable-background="new 0 0 32 32"
    id="Editable-line"
    version="1.1"
    xml:space="preserve"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <circle
      cx="16"
      cy="16"
      fill="currentColor"
      id="XMLID_55_"
      r="2"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
    <circle
      cx="16"
      cy="26"
      fill="currentColor"
      id="XMLID_54_"
      r="2"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
    <circle
      cx="16"
      cy="6"
      fill="currentColor"
      id="XMLID_52_"
      r="2"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-miterlimit="10"
      stroke-width="2"
    />
  </svg>
</template>
