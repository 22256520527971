// src/firebase/index.ts
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { firebaseConfig } from './firebaseConfig'

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize Firebase Analytics (Optional)
const analytics = getAnalytics(app)

// Initialize Firestore
export const db = getFirestore(app)

// Initialize Firebase Auth
export const auth = getAuth(app)

export { app, analytics }
