<template>
  <div class="alert-btn-container">
    <button @click="showAlert" class="test-button">Default Alert</button>
    <button @click="showAlertSuccess" id="btn-success" class="test-button">Success Alert</button>
    <button @click="showAlertError" id="btn-error" class="test-button">Error Alert</button>
    <button @click="showAlertWarning" id="btn-warning" class="test-button">Warning Alert</button>
  </div>
  <AlertContainer />
</template>

<script setup lang="ts">
import { useAlertService } from '@/services/lugand-api-services/alertService'
import AlertContainer from '@/components/AlertContainer.vue'

const { addAlert } = useAlertService()

const showAlert = () => {
  addAlert('Default Alert', 'This is a test alert message.', 'default')
}

const showAlertSuccess = () => {
  addAlert('Success Alert', 'This is a test alert message.', 'success')
}

const showAlertError = () => {
  addAlert('Error Alert', 'This is a test alert message.', 'error')
}

const showAlertWarning = () => {
  addAlert('Warning Alert', 'This is a test alert message.', 'warning')
}
</script>

<style>
.alert-btn-container {
  display: flex;
  justify-content: center;
  max-width: 100%;
  gap: 3px;
}

.test-button {
  padding: 10px 20px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .alert-btn-container {
    display: block;
    max-width: 100%;
  }

  .test-button {
    margin: 5px;
  }
}
</style>
