import axios from 'axios'

// API Base URL
const BASE_URL = 'http://localhost:3000'

// Object Interface Types
export interface Product {
  id: number // Use number instead of string for consistency with JSON server data
  name: string
  description: string
  price: number
  quantity: number
  image: string
  images?: string[]
  categories?: string[]
}

export interface ProductCategory {
  id: number
  name: string
  products_id: number[]
}

export const getProductById = async (id: number): Promise<Product | null> => {
  try {
    const response = await axios.get(`${BASE_URL}/products/?id=${id}`)
    const products: Product[] = response.data

    // Assuming the response contains an array of products, take the first one
    if (products.length > 0) {
      return products[0] // Return the first product from the array
    } else {
      return null // Return null if no product is found
    }
  } catch (error) {
    console.error('Error fetching product by ID:', error)
    return null
  }
}

// Fetch all products
export const getAllProducts = async (): Promise<Product[]> => {
  try {
    const response = await axios.get(`${BASE_URL}/products`)
    console.log('Fetched all products:', response.data)
    return response.data
  } catch (error) {
    console.error('Error fetching all products:', error)
    return []
  }
}

// Fetch all ProductImages by ProductId
export const getProductImagesById = async (id: number): Promise<string[]> => {
  try {
    const response = await axios.get(`${BASE_URL}/product-images?product_id=${id}`)
    const productImages = response.data[0]?.['product-images'] || []
    console.log('getProductImagesById:', productImages)
    return productImages
  } catch (error) {
    console.error('Error fetching product images by ID:', error)
    return []
  }
}

// Fetch all product categories
export const getAllProductCategories = async (): Promise<ProductCategory[]> => {
  try {
    const response = await axios.get(`${BASE_URL}/product-categories`)
    console.log('Fetched product categories:', response.data)
    return response.data || []
  } catch (error) {
    console.error('Error fetching product categories:', error)
    return []
  }
}

// COMPONENTS:
// Mock for component: BannerCard.vue
export interface Banner {
  image: string
  title: string
  description: string
}
const banners: Banner[] = [
  {
    image: 'https://via.placeholder.com/800x400',
    title: 'Banner 1',
    description: '1 This is the first banner description.'
  },
  {
    image: 'https://via.placeholder.com/800x400',
    title: 'Banner 2',
    description: '1 This is the first banner description.'
  },
  {
    image: 'https://via.placeholder.com/800x400',
    title: 'Banner 3',
    description: '1 This is the first banner description.'
  },
  {
    image: 'https://via.placeholder.com/800x400',
    title: 'Banner 4',
    description: '1 This is the first banner description.'
  },
  {
    image: 'https://via.placeholder.com/800x400',
    title: 'Banner 5',
    description: '1 This is the first banner description.'
  }
]
export const getAllBanners = (): Promise<Banner[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      console.log('getAllBanners: ', banners)
      resolve(banners)
    }, 500) // Simulate a network delay
  })
}

// Mock for component: GenericTable.vue
const tableData = [
  { Column1: 'value 1', Column2: 'value 2' },
  { Column1: 'value 1', Column2: 'value 2' },
  { Column1: 'value 1', Column2: 'value 2' },
  { Column1: 'value 1', Column2: 'value 2' }
]
export const getTableData = (): Promise<Record<string, any>[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      console.log('getTableData:', tableData)
      resolve(tableData)
    }, 500) // Simulate a network delay
  })
}
