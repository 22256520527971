<template>
  <hr />
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-section about">
        <h3>About Us</h3>
        <p>
          We are a company dedicated to providing the best products and services. Our mission is to
          bring quality and value to our customers.
        </p>
      </div>
      <div class="footer-section links">
        <h3>Quick Links</h3>
        <ul>
          <li><router-link to="/">Home</router-link></li>
          <li><router-link to="/">About</router-link></li>
          <li><router-link to="/">Products</router-link></li>
          <li><router-link to="/">Contact</router-link></li>
        </ul>
      </div>
      <div class="footer-section contact">
        <h3>Contact Us</h3>
        <p>Email: info@company.com</p>
        <p>Phone: +123 456 7890</p>
        <div class="social-links">
          <a href="https://facebook.com" target="_blank"><i class="fab fa-facebook"></i></a>
          <a href="https://twitter.com" target="_blank"><i class="fab fa-twitter"></i></a>
          <a href="https://instagram.com" target="_blank"><i class="fab fa-instagram"></i></a>
        </div>
      </div>
    </div>
    <div class="footer-bottom">
      <p>&copy; 2023 Your Company. All rights reserved.</p>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'FooterMenu'
})
</script>

<style scoped>
.footer {
  padding: 20px 0;
  text-align: center;
  background-color: var(--color-border);
  color: aliceblue;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.footer-section {
  flex: 1;
  margin: 10px;
}

.footer-section h3 {
  margin-bottom: 15px;
}

.footer-section ul {
  list-style: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 10px;
}

.footer-section ul li a {
  color: aliceblue;
  text-decoration: none;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.footer-section .social-links a {
  margin: 0 10px;
  color: aliceblue;
  text-decoration: none;
  font-size: 1.5em;
}

.footer-bottom {
  margin-top: 20px;
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    margin: 20px 0;
  }
}
</style>
