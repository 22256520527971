<template>
  <div @click="goToApiProductDetails" class="product border">
    <div v-if="isDiscountValid" class="discount-banner">Discount</div>
    <img v-if="firstImage" :src="firstImage" alt="Product" class="product-image" />
    <div class="product-info">
      <p>{{ product.name }}</p>
      <p class="text-valor-a-vista">Original Price: {{ product.price.valor_original || '-' }}</p>
      <p>Price à Vista: {{ product.price.valor_a_vista || '-' }}</p>
      <p>
        Parcelado:
        {{
          product.price.parcelado
            ? `${product.price.parcelado.valor} em
        ${product.price.parcelado.numero_parcelas}x`
            : '-'
        }}
      </p>
    </div>
    <p v-if="isDiscountValid" class="discount-timer">acaba em: {{ timeRemaining }}</p>
    <p v-else class="discount-timer"></p>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, onMounted, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { type Product } from '../services/lugand-api-services/productService'

export default defineComponent({
  name: 'ProductCard',
  props: {
    product: {
      type: Object as () => Product,
      required: true
    }
  },
  setup(props) {
    const router = useRouter()
    const timeRemaining = ref('')
    const isDiscountValid = ref(false)

    const goToApiProductDetails = () => {
      router.push({ name: 'product-details', params: { id: props.product.id } })
    }

    const firstImage = computed(() => {
      return props.product.images.length > 0 ? props.product.images[0].image : null
    })

    const calculateTimeRemaining = () => {
      if (!props.product.discount) return

      const endTime = new Date(props.product.discount.data_termino).getTime()
      const now = new Date().getTime()
      const timeDiff = endTime - now

      if (timeDiff <= 0) {
        isDiscountValid.value = false
        return
      }

      isDiscountValid.value = true

      const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24))
      const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000)

      timeRemaining.value = `${days} Dias e ${hours}:${minutes}:${seconds}`
    }

    let interval: any

    onMounted(() => {
      if (props.product.discount) {
        calculateTimeRemaining()
        interval = setInterval(calculateTimeRemaining, 1000)
      }
    })

    onUnmounted(() => {
      clearInterval(interval)
    })

    return {
      goToApiProductDetails,
      firstImage,
      timeRemaining,
      isDiscountValid
    }
  }
})
</script>

<style scoped>
.text-valor-a-vista {
  text-decoration: line-through;
  color: grey;
}

.product {
  width: 100%;
  position: relative;
  transition: transform 0.2s;
}

.product:hover {
  cursor: pointer;
  transform: scale(1.02);
}

.product-image {
  width: 100%;
  height: 200px;
  margin-bottom: 0px;
}

.product-info {
  padding: 10px;
  height: 150px;
}

.discount-banner {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: var(--color-border);
  color: var(--color-background);
  padding: 5px;
  font-weight: bold;
  border-bottom-left-radius: 25%;
}

.discount-timer {
  background-color: var(--color-border);
  color: var(--color-background);
  height: 25px;
  text-align: center;
}
</style>
