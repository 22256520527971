<template>
  <div class="container border">
    <h2>Cart List</h2>
    <div v-for="cart in carts" :key="cart.id" class="cart">
      <a @click="viewCartDetails(cart.id)">
        <h2>{{ cart.name }}</h2>
        <ul>
          <li>Items Quantity: {{ calculateTotalQuantity(cart) }}</li>
          <li>Total Value: {{ calculateTotalPrice(cart) }}</li>
        </ul>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted } from 'vue'
import { useCartStore, type FullCart } from '@/stores/development/cartStore'
import { useRouter } from 'vue-router'

export default defineComponent({
  name: 'CartList',
  setup() {
    const cartStore = useCartStore()
    const router = useRouter()

    const carts = computed(() => cartStore.carts)

    const viewCartDetails = (cartId: number) => {
      router.push({ name: 'cart-details', params: { id: cartId } })
    }

    const calculateTotalQuantity = (cart: FullCart) => {
      return cart.items.reduce((total, item) => total + item.quantity, 0)
    }

    const calculateTotalPrice = (cart: FullCart) => {
      return cart.items
        .reduce(
          (total, item) => total + Number(item.product.price.valor_a_vista) * item.quantity,
          0
        )
        .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }

    onMounted(() => {
      cartStore.fetchCarts()
    })

    return {
      carts,
      viewCartDetails,
      calculateTotalQuantity,
      calculateTotalPrice
    }
  }
})
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0%;
}

.container {
  margin: 10px;
}

.cart {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid var(--color-border);
  border-radius: 5px;
  cursor: pointer;
  width: 300px;
}

.cart:hover {
  background-color: var(--color-border);
  color: var(--color-background);
}

@media (max-width: 768px) {
  .cart {
    width: 200px;
  }
}
</style>
