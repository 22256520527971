<template>
  <div class="loader-container">
    <svg>
      <text x="48%" y="65%" text-anchor="middle" class="text-body">Lugand</text>
      <text x="50%" y="65%" dy="0.8em" dx="1.8em" text-anchor="middle" id="sub" class="text-body">
        sistemas
      </text>
    </svg>
  </div>
</template>

<style scoped>
.loader-container {
  text-align: center;
  width: 220px;
  height: 100px;
  margin: auto;
  border: 1px solid var(--color-border);
  border-top-right-radius: 25%;
  border-bottom-left-radius: 25%;
  box-shadow: 5px 5px 10px var(--color-shadow-border);
  background-color: var(--color-background);
}
svg {
  max-width: 100%;
  max-height: 100%;
}

svg text {
  font-size: 4rem;
  stroke-width: 2;
  letter-spacing: -7px;
}
svg text#sub.text-body {
  font-size: 1.4rem;
  stroke-width: 2;
  letter-spacing: 0px;
}

svg text.text-body {
  fill: var(--color-text);
  animation: 6s alternate animate-stroke; /* infinite */
}

@media (max-width: 768px) {
  svg text {
    font-size: 4rem;
    stroke-width: 2;
    letter-spacing: -7px;
    padding: 0%;
    margin: 0%;
  }

  svg text#sub.text-body {
    font-size: 1.4rem;
    stroke-width: 2;
    letter-spacing: 0px;
    padding: 0%;
    margin: 0%;
  }
}

@keyframes animate-stroke {
  0% {
    fill: transparent;
    stroke: var(--color-border);
    stroke-width: 3;
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 32%;
  }
  50% {
    fill: transparent;
    stroke: var(--color-border);
    stroke-width: 3;
  }
  80%,
  100% {
    fill: var(--color-text);
    stroke: transparent;
    stroke-width: 0;
    stroke-dashoffset: -25%;
    stroke-dasharray: 32% 0;
  }
}
</style>
