import axios from 'axios'

const BASE_URL = 'http://localhost:8000/api/product'
const IMAGES_URL = `${BASE_URL}/product-images`

export interface Price {
  id: number
  parcelado: {
    id: number
    valor: string
    numero_parcelas: number
    taxa_juros: string
    created_at: string
  }
  product: number
  valor_original: string
  valor_a_vista: string
  created_at: string
}

export interface Vendor {
  id: number
  name: string
  cnpj: string
  ativo: boolean
  created_at: string
  last_update: string
}

export interface Category {
  id: number
  name: string
}

export interface Subcategory {
  id: number
  name: string
  categories: number[]
}

export interface Discount {
  id: number
  tipo: {
    id: number
    name: string
    created_at: string
  }
  valor: string
  descricao: string
  data_inicio: string
  data_termino: string
  created_at: string
  ativo: boolean
}

export interface Image {
  id: number
  image: string
  product: number
}

export interface Property {
  id: number
  key: {
    id: number
    key: string
    created_at: string
  }
  value: string
  created_at: string
}

export interface Keyword {
  id: number
  word: string
}

export interface Product {
  id: number
  price: Price
  vendor: Vendor
  categories: Category[]
  subcategories: Subcategory[]
  discount: Discount
  images: Image[]
  properties: Property[]
  keywords: Keyword[]
  name: string
  description: string
  stock_quantity: number
  created_at: string
  updated_at: string
  ativo: boolean
}

export const getAllProducts = async (): Promise<Product[]> => {
  try {
    const response = await axios.get(`${BASE_URL}/products`)
    console.log('Fetched products:', response.data)
    return response.data || []
  } catch (error) {
    console.error('Error fetching products:', error)
    return []
  }
}

export const getProductById = async (id: number): Promise<Product | null> => {
  try {
    const response = await axios.get(`${BASE_URL}/products/${id}`)
    console.log('Fetched product by ID:', response.data)
    return response.data || null
  } catch (error) {
    console.error('Error fetching product by ID:', error)
    return null
  }
}

export const getProductImagesById = async (productId: number) => {
  try {
    const response = await axios.get(`${IMAGES_URL}/?product=${productId}`)
    return response.data.filter((image: any) => image.product === productId)
  } catch (error) {
    console.error('Error fetching product images by ID:', error)
    throw error
  }
}

export const getAllProductImages = async (): Promise<Image[]> => {
  try {
    const response = await axios.get(`${IMAGES_URL}/`)
    console.log('Fetched product images:', response.data)
    const images: Image[] = response.data.flatMap((product: Product) => product.images)
    return images
  } catch (error) {
    console.error('Error fetching product images:', error)
    return []
  }
}

export const getAllPrices = async (): Promise<Price[]> => {
  try {
    const response = await axios.get(`${BASE_URL}/prices`)
    console.log('Fetched prices:', response.data)
    return response.data || []
  } catch (error) {
    console.error('Error fetching prices:', error)
    return []
  }
}

export const getPricesByProductId = async (productId: number): Promise<Price | null> => {
  try {
    const response = await axios.get(`${BASE_URL}/prices/?product=${productId}`)
    console.log('Fetched prices by product ID:', response.data)
    return response.data.length > 0 ? response.data[0] : null
  } catch (error) {
    console.error('Error fetching prices by product ID:', error)
    return null
  }
}

export const getAllProductCategories = async (): Promise<Category[]> => {
  try {
    const response = await axios.get(`${BASE_URL}/products`)
    console.log('Fetched product categories:', response.data)
    const categories: Category[] = response.data.flatMap((product: Product) => product.categories)

    // Remove duplicate categories
    const uniqueCategories = Array.from(new Set(categories.map((cat: Category) => cat.id)))
      .map((id) => categories.find((cat) => cat.id === id))
      .filter((cat): cat is Category => cat !== undefined) // Filtra valores undefined

    return uniqueCategories
  } catch (error) {
    console.error('Error fetching product categories:', error)
    return []
  }
}
