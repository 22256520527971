<template>
  <div class="search-page">
    <div class="filter-bar border">
      <SidebarFilter />
    </div>
    <div class="container">
      <h1>Search Results</h1>
      <div class="card-container" v-if="paginatedProducts.length">
        <ProductCard
          class="card-box"
          v-for="product in paginatedProducts"
          :key="product.id"
          :product="product"
        />
      </div>
      <div v-else>No products found.</div>
      <Paginator
        v-if="totalPages > 1"
        :currentPage="currentPage"
        :totalPages="totalPages"
        @page-change="handlePageChange"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import { useRoute } from 'vue-router'
import {
  getAllProducts,
  getAllPrices,
  type Product
} from '@/services/lugand-api-services/productService'
import ProductCard from '@/components/ProductCard.vue'
import Paginator from '@/components/GenericPaginator.vue'
import SidebarFilter from '@/components/SidebarFilter.vue'

const route = useRoute()
const products = ref<Product[]>([])
const filteredProducts = ref<Product[]>([])
const currentPage = ref(1)
const itemsPerPage = ref(10) // Number of items per page

const totalPages = computed(() => Math.ceil(filteredProducts.value.length / itemsPerPage.value))

const paginatedProducts = computed(() => {
  const start = (currentPage.value - 1) * itemsPerPage.value
  const end = start + itemsPerPage.value
  return filteredProducts.value.slice(start, end)
})

const filterProducts = async () => {
  // Add async
  const query = route.query.q?.toString().toLowerCase() || ''
  let tempFilteredProducts = products.value.filter(
    (product) =>
      product.name.toLowerCase().includes(query) ||
      product.description.toLowerCase().includes(query)
  )

  const minPrice = route.query.minPrice ? parseFloat(route.query.minPrice as string) : null
  const maxPrice = route.query.maxPrice ? parseFloat(route.query.maxPrice as string) : null

  if ((minPrice !== null && !isNaN(minPrice)) || (maxPrice !== null && !isNaN(maxPrice))) {
    // Add this condition
    const allPrices = await getAllPrices() // Fetch all prices
    tempFilteredProducts = tempFilteredProducts.filter((product) => {
      const productPrice = allPrices.find((price) => price.product === product.id)
      if (!productPrice) return false
      const price = parseFloat(productPrice.valor_a_vista)
      return (minPrice === null || price >= minPrice) && (maxPrice === null || price <= maxPrice)
    })
  }

  filteredProducts.value = tempFilteredProducts
  currentPage.value = 1 // Reset to first page when filter changes
}

const handlePageChange = (page: number) => {
  currentPage.value = page
}

onMounted(async () => {
  try {
    const allProducts = await getAllProducts()
    products.value = allProducts
    filterProducts()
  } catch (error) {
    console.error('Error fetching products:', error)
  }
})

watch(() => route.query.q, filterProducts)
watch(() => route.query.minPrice, filterProducts)
watch(() => route.query.maxPrice, filterProducts)
</script>

<style scoped>
.sidebar-searcher {
  padding: 0.5em;
  height: auto;
  overflow: visible;
}

.search-page {
  display: flex;
  min-height: 100vh;
}

.container {
  width: 60%;
}

.filter-bar {
  width: 200px;
  width: 20%;
  padding: 10px;
  margin: 0.5rem;
}

.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 16px;
}

.card-box {
  max-width: 300px;
  width: 100%;
  padding: 5px;
}

@media (max-width: 768px) {
  .search-page {
    display: block;
  }

  .container {
    width: 100%;
    margin: auto;
  }

  .filter-bar {
    width: 100%;
    margin: 0rem;
  }
}
</style>
