// src/services/alertService.ts
import { ref } from 'vue'

interface Alert {
  id: number
  title: string
  message: string
  icon: string
  visible: boolean
  customClass: string
}

const alerts = ref<Alert[]>([])

const icons = {
  default: new URL('@/assets/images/logo_vue.svg', import.meta.url).href,
  success: new URL('@/assets/images/logo_vue.svg', import.meta.url).href,
  error: new URL('@/assets/images/logo_vue.svg', import.meta.url).href,
  warning: new URL('@/assets/images/logo_vue.svg', import.meta.url).href
}

const addAlert = (
  title: string,
  message: string,
  type: 'default' | 'success' | 'error' | 'warning'
) => {
  const id = Date.now()
  const icon = icons[type]
  const customClass = `custom-alert-${type}`
  console.log(`Adding alert with class: ${customClass}`) // Adicionar log para verificar a classe
  alerts.value.push({
    id,
    title,
    message,
    icon,
    visible: true,
    customClass
  })
}

const removeAlert = (id: number) => {
  alerts.value = alerts.value.filter((alert) => alert.id !== id)
}

export const useAlertService = () => {
  return {
    alerts,
    addAlert,
    removeAlert
  }
}
