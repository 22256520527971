// src/stores/themeStore.ts
import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useThemeStore = defineStore('theme', () => {
  const theme = ref<string>('light')

  const setTheme = (newTheme: string) => {
    theme.value = newTheme
    document.documentElement.setAttribute('data-theme', newTheme)
    localStorage.setItem('theme', newTheme)
    console.log(`Theme set to: ${newTheme}`)
  }

  const toggleTheme = () => {
    theme.value = theme.value === 'light' ? 'dark' : 'light'
    setTheme(theme.value)
    console.log(`Theme toggled to: ${theme.value}`)
  }

  const loadTheme = () => {
    const savedTheme = localStorage.getItem('theme')
    if (savedTheme) {
      setTheme(savedTheme)
      console.log(`Loaded theme: ${savedTheme}`)
    } else {
      setTheme('light')
      console.log('Loaded default theme: light')
    }
  }

  return {
    theme,
    setTheme,
    toggleTheme,
    loadTheme
  }
})
