<template>
  <div class="container">
    <ProductDetails />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useRoute } from 'vue-router'
import ProductDetails from '@/components/ProductDetails.vue'

export default defineComponent({
  name: 'ProductDetailsView',
  components: {
    ProductDetails
  },
  setup() {
    const route = useRoute()
    const productId = route.params.id as string

    return {
      productId
    }
  }
})
</script>

<style scoped>
.container {
  width: 60%;
  margin: auto;
}
@media (max-width: 1350px) {
  .container {
    width: 100%;
  }
}
</style>
