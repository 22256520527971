<!-- src/views/CartView.vue -->
<template>
  <div class="cart-view">
    <div class="cart-container">
      <CartList />
      <router-view />
      <!-- Render child route components here -->
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import CartList from '@/components/CartList.vue'

export default defineComponent({
  name: 'CartView',
  components: {
    CartList
  }
})
</script>

<style scoped>
.cart-view {
  display: flex;
  gap: 20px;
}

.cart-container {
  display: flex;
  flex-direction: row;
}
</style>
