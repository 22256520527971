<template>
  <button @click="handleAuthAction" class="auth-button">
    {{ buttonText }}
  </button>
  <AlertContainer />
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { auth, logout } from '@/services/lugand-api-services/authService'
import { onAuthStateChanged } from 'firebase/auth'
import { useAlertService } from '@/services/lugand-api-services/alertService'
import AlertContainer from '@/components/AlertContainer.vue'

const router = useRouter()
const buttonText = ref('LOGIN')
const { addAlert } = useAlertService()

const showAlert = (
  title: string,
  message: string,
  type: 'default' | 'success' | 'error' | 'warning'
) => {
  addAlert(title, message, type)
}

const handleAuthAction = async () => {
  if (auth.currentUser) {
    await logout()
    buttonText.value = 'LOGIN'
    showAlert('Success', 'Logout successful', 'success')
    setTimeout(() => router.push('/'), 2000)
  } else {
    router.push('/login')
  }
}

onMounted(() => {
  onAuthStateChanged(auth, (user) => {
    buttonText.value = user ? 'LOGOUT' : 'LOGIN'
  })
})
</script>

<style scoped>
.auth-button {
  padding: 8px 20px;
  border: none;
  color: var(--color-text);
  cursor: pointer;
  font-size: 16px;
  height: 36px;
  width: 100%;
}
</style>
