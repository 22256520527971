<template>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th v-for="(header, index) in headers" :key="index">{{ header }}</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(row, rowIndex) in rows"
          :key="rowIndex"
          :class="{ 'alt-row': rowIndex % 2 === 0 }"
        >
          <td v-for="(header, colIndex) in headers" :key="colIndex">{{ row[header] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue'

export default defineComponent({
  name: 'GenericTable',
  props: {
    data: {
      type: Array as PropType<Record<string, any>[]>,
      required: true
    }
  },
  computed: {
    headers(): string[] {
      if (this.data.length > 0) {
        return Object.keys(this.data[0])
      }
      return []
    },
    rows(): Record<string, any>[] {
      return this.data
    }
  }
})
</script>

<style scoped>
.table-container {
  width: 100%;
  overflow-x: auto;
  border-radius: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 8px 12px;
  text-align: left;
  background-color: var(--color-background);
}

th {
  background-color: var(--color-text);
  color: var(--color-background);
}

/* Alternating row colors */
tbody tr.alt-row td {
  background-color: var(--color-border);
  color: var(--color-text);
}

tbody tr:not(.alt-row) td {
  background-color: var(--color-background);
  color: var(--color-text);
}

/* Borders only between td elements */
td + td {
  border-left: 1px solid var(--color-text);
}
</style>
