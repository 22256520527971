<template>
  <div v-if="selectedCart" class="cart-details">
    <h2>{{ selectedCart.name }}</h2>
    <div class="cart-item">
      <div v-for="item in selectedCart.items" :key="item.product.id">
        <CartItem :item="item" />
      </div>
    </div>
    <div class="cart-total">
      <strong>Total: {{ calculateCartTotal() }}</strong>
    </div>
  </div>
  <div v-else>
    <p>Cart not found.</p>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useCartStore } from '@/stores/development/cartStore' // Correctly import FullCart
import CartItem from '@/components/CartItem.vue'

export default defineComponent({
  name: 'CartDetails',
  components: { CartItem },
  setup() {
    const route = useRoute()
    const cartStore = useCartStore()

    const selectedCart = computed(() => cartStore.selectedCart)

    watch(
      () => route.params.id,
      (newId) => {
        if (newId) {
          cartStore.fetchCartById(Number(newId))
        }
      },
      { immediate: true }
    )

    const calculateCartTotal = () => {
      if (!selectedCart.value) return 'R$0.00'
      return selectedCart.value.items
        .reduce(
          (total, item) => total + Number(item.product.price.valor_a_vista) * item.quantity,
          0
        )
        .toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }

    return {
      selectedCart,
      calculateCartTotal
    }
  }
})
</script>

<style scoped>
.cart-details {
  margin: 20px;
  padding-left: 20px;
}

.cart-item {
  display: flex;
  gap: 0.5em;
}

.cart-total {
  margin-top: 20px;
  font-size: 18px;
}
</style>
