<template>
  <transition name="slide-fade">
    <div
      v-if="showAlert"
      :class="['alert', customClass]"
      :style="{ top: `${index * 76 + 35}px` }"
      @animationend="resetAnimation"
    >
      <div class="alert-content">
        <slot name="icon">
          <img :src="icon" alt="icon" class="alert-icon" />
        </slot>
        <div class="alert-text">
          <h3 class="alert-title">{{ title }}</h3>
          <p class="alert-message">{{ message }}</p>
        </div>
        <button @click="closeAlert" class="close-button">X</button>
      </div>
      <div class="progress-bar" :style="{ width: progressBarWidth + '%' }"></div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { useAlertService } from '@/services/lugand-api-services/alertService'

const props = defineProps({
  id: {
    type: Number,
    required: true
  },
  title: {
    type: String,
    required: true
  },
  message: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    default: ''
  },
  customClass: {
    type: String,
    default: ''
  },
  index: {
    type: Number,
    required: true
  }
})

const { removeAlert } = useAlertService()
const showAlert = ref(true)
const progressBarWidth = ref(100)
let intervalId: number | undefined

const closeAlert = () => {
  showAlert.value = false
  clearInterval(intervalId)
  removeAlert(props.id)
}

const resetAnimation = () => {
  if (showAlert.value) {
    progressBarWidth.value = 100
    clearInterval(intervalId)
    intervalId = window.setInterval(() => {
      progressBarWidth.value -= 1
      if (progressBarWidth.value <= 0) {
        closeAlert()
      }
    }, 50)
  }
}

onMounted(() => {
  resetAnimation()
})

onBeforeUnmount(() => {
  clearInterval(intervalId)
})
</script>

<style>
.alert {
  position: fixed;
  top: 0;
  right: 0;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: var(--color-background);
  color: var(--color-text);
  border: 1px solid var(--color-border);
  z-index: 1005;
  width: 350px;
  animation:
    shake 0.5s,
    box-shadow-pulse 0.5s;
}

.alert-content {
  display: flex;
  align-items: center;
}

.alert-icon {
  width: 32px;
  height: 32px;
  margin-right: 15px;
}

.alert-text {
  display: flex;
  flex-direction: column;
}

.alert-title {
  margin: 0;
  font-size: 18px;
  font-weight: bold;
}

.alert-message {
  margin: 0;
  font-size: 16px;
}

.close-button {
  background: var(--color-border);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  margin-left: 20px;
  margin-top: 5px;
  cursor: pointer;
  z-index: 10;
}

.progress-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 5px;
  background-color: var(--color-border);
  transition: width 0.05s linear;
}

@keyframes shake {
  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(-5px);
  }

  50% {
    transform: translateX(5px);
  }

  75% {
    transform: translateX(-5px);
  }
}

@keyframes box-shadow-pulse {
  0%,
  100% {
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  50% {
    box-shadow: 0 4px 20px rgba(153, 0, 255, 0.8);
  }
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

@media (max-width: 768px) {
  .alert {
    width: 100%;
  }
}
</style>
