<template>
  <div class="dropdown" @mouseleave="isOpen = false">
    <button @click="toggleDropdown" class="dropdown-button">
      <slot name="icon"></slot>
      {{ buttonText }}
    </button>
    <div v-if="isOpen" class="dropdown-menu" @click="isOpen = false">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'DropDownMenu',
  props: {
    buttonText: {
      type: String,
      required: true
    }
  },
  setup() {
    const isOpen = ref(false)

    const toggleDropdown = () => {
      isOpen.value = !isOpen.value
    }

    return {
      isOpen,
      toggleDropdown
    }
  }
})
</script>

<style scoped>
.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-button {
  color: var(--color-text);
  background-color: var(--color-background);
  display: flex;
  cursor: pointer;
  height: 36px;
  border: 1px solid var(--color-border);
}

.dropdown-button:hover {
  background-color: var(--color-border);
  color: aliceblue;
}

.dropdown-menu {
  display: block;
  position: absolute;
  background-color: var(--color-background);
  color: var(--color-text);
  box-shadow: 0px 8px 16px 0px var(--color-shadow-border);
  z-index: 1;
  right: 0;
  min-width: 100%;
  max-width: 100vw;
  width: 200px;
  margin-right: -7px;
  overflow: hidden;
  border: 1px solid var(--color-border);
  border-radius: 5px;
}

.dropdown-menu :deep(> *) {
  padding: 12px 16px;
  cursor: pointer;
}

.dropdown-menu :deep(> *):hover {
  background-color: var(--color-border);
  color: aliceblue;
}
</style>
