<!-- src/components/user_auth_components/UserRegister.vue -->
<template>
  <div class="register-container">
    <div class="register-banner">
      <div class="box-content">
        <div>
          <img src="@/assets/images/cute-robot.webp" alt="robo" />
        </div>
        <div>
          <div class="texto">
            <h3>Registre-se e obtenha acesso a nossas vantagens</h3>
            <p>Ao criar sua conta você terá acesso a compra em nossa loja e promoções especiais</p>
          </div>
          <div class="texto">
            <h3>Para empresas</h3>
            <p>
              Faça login com o email empresarial, visite a tela de conta e preencha as informações
              sobre a empresa, temos orçamentos especiais para CNPJ
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="register-box">
      <LogoAnimation />
      <br />
      <h2>Register</h2>
      <form @submit.prevent="handleRegister">
        <input class="input" v-model="email" type="email" placeholder="Email" required />
        <input class="input" v-model="password" type="password" placeholder="Password" required />
        <input
          class="input"
          v-model="confirmPassword"
          type="password"
          placeholder="Confirm Password"
          required
        />
        <button id="register-button" type="submit">Register</button>
      </form>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
      <button class="discrete-button" @click="navigateToLogin" type="submit">Back to Login</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import { register } from '@/services/lugand-api-services/authService'
import LogoAnimation from '../animations/LogoAnimation.vue'

const email = ref('')
const password = ref('')
const confirmPassword = ref('')
const errorMessage = ref('')
const router = useRouter()

const handleRegister = async () => {
  if (password.value !== confirmPassword.value) {
    errorMessage.value = 'Passwords do not match'
    return
  }

  try {
    await register(email.value, password.value)
    alert(
      'Registration successful, access your email and confirm that it is you there before login'
    )
    router.push('/login')
  } catch (error: any) {
    errorMessage.value = 'Registration failed, try again'
    console.log('Registration error:', error.message)
  }
}

const navigateToLogin = () => {
  router.push('/login')
}
</script>

<style scoped>
.register-banner {
  border: 1px solid var(--color-border);
  border-radius: 8px;
  height: 426px;
  width: 652px;
  margin-right: 10px;
  padding: 10px;
  background-image: url('@/assets/images/background-banner-login.jpg');
  background-size: cover;
  background-position: center;
}

.box-content {
  display: flex;
  gap: 10px;
}

.texto {
  display: block;
  background-color: rgba(159, 206, 207, 0.13);
  padding: 10px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  margin-bottom: 8px;
  border-radius: 8px;
  color: aliceblue;
}
input {
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
}

button {
  padding: 5px;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 8px;
  width: 100%;
}

.discrete-button {
  border: none;
  padding: 3px;
  margin: 0%;
  width: auto;
  color: var(--color-border);
  display: block;
  margin-top: 10px;
}

#register-button {
  background-color: blue;
  color: white;
}

.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60vh;
  max-width: 100%;
}

.register-box {
  padding: 2rem;
  border-radius: 8px;
  border: 1px solid var(--color-border);
  box-shadow: 2px 2px 8px 2px var(--color-shadow-border);
  text-align: center;
  background-image: url('@/assets/images/background-login.jpg');
  background-size: cover;
  background-position: center;
}

.register-box h2 {
  background-color: var(--color-border);
  color: aliceblue;
  margin-bottom: 1rem;
}

.register-box form {
  display: flex;
  flex-direction: column;
}

.error {
  color: var(--color-text);
  color: red;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 980px) {
  .register-banner {
    display: none;
  }

  .register-container {
    height: 80vh;
  }
}
</style>
