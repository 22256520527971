<template>
  <div class="search-bar">
    <input
      type="text"
      v-model="searchQuery"
      @keyup.enter="handleSearch"
      placeholder="Search for products..."
    />
    <button @click="handleSearch"><IconSearch /></button>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import IconSearch from './icons/IconSearch.vue'

const searchQuery = ref('')
const router = useRouter()

const handleSearch = () => {
  if (searchQuery.value.trim()) {
    router.push({ name: 'search-results', query: { q: searchQuery.value } })
  }
}
</script>

<style scoped>
.search-bar {
  display: flex;
  align-items: center;
  gap: 0;
}
.search-bar input {
  width: 320px;
  height: 36px;
  padding: 5px;
  border-radius: 0px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

button {
  height: 36px;
  padding-top: 5px;
  cursor: pointer;
  color: var(--color-text);
  background-color: var(--color-background);
  border: 1px solid var(--color-border);
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
button:hover {
  background-color: var(--color-border);
  color: var(--color-background);
}

@media (max-width: 768px) {
  .search-bar input {
    width: 100%;
  }
}
</style>
