import { defineStore } from 'pinia'
import { ref } from 'vue'
import { getAllCarts, getCartById } from '@/services/development/cartService'
import { type Product } from '@/services/lugand-api-services/productService'

// Define the interfaces here
export interface FullCartItem {
  product: Product
  quantity: number
}

export interface FullCart {
  id: number
  name: string
  items: FullCartItem[]
}

export const useCartStore = defineStore('cart', () => {
  const carts = ref<FullCart[]>([])
  const selectedCart = ref<FullCart | null>(null)

  const fetchCarts = async () => {
    try {
      const fetchedCarts = await getAllCarts()
      carts.value = fetchedCarts
      console.log('Fetched carts:', fetchedCarts)
    } catch (error) {
      console.error('Error fetching carts from store:', error)
    }
  }

  const fetchCartById = async (id: number) => {
    try {
      selectedCart.value = await getCartById(id)
      console.log('Fetched cart by ID:', selectedCart.value)
    } catch (error) {
      console.error('Error fetching cart by ID from store:', error)
    }
  }

  return {
    carts,
    selectedCart,
    fetchCarts,
    fetchCartById
  }
})
