<template>
  <div class="alert-container">
    <AlertComponent
      v-for="(alert, index) in alerts"
      :key="alert.id"
      :id="alert.id"
      :title="alert.title"
      :message="alert.message"
      :icon="alert.icon"
      :customClass="alert.customClass"
      :index="index"
    />
  </div>
</template>

<script setup lang="ts">
import { useAlertService } from '@/services/lugand-api-services/alertService'
import AlertComponent from '@/components/AlertComponent.vue'

const { alerts } = useAlertService()
</script>

<style>
.alert-container {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
}
</style>
