<template>
  <div class="logo-bg-container">
    <svg>
      <defs>
        <path
          fill="currentColor"
          id="a"
          d="M0.74-509.63l485.39 352.65l-185.4 570.61h-599.97l-185.4-570.61L0.74-509.63 M0.74-510.87l-486.56 353.51l185.85 571.99h601.42L487.3-157.36L0.74-510.87L0.74-510.87z"
        />
      </defs>
      <g transform="scale(0.9)" style="transform-origin: center">
        <g transform="rotate(-9 0 0)" style="transform-origin: center">
          <g transform="rotate(-250.4 0 0)" style="transform-origin: center">
            <g transform="translate(800 170)">
              <use stroke="#1270FA" href="#a" transform="rotate(9.4 0 0) scale(1.094)" />
              <use stroke="#117ae9" href="#a" transform="rotate(18.8 0 0) scale(1.188)" />
              <use stroke="#1083d9" href="#a" transform="rotate(28.2 0 0) scale(1.282)" />
              <use stroke="#0e8dc8" href="#a" transform="rotate(37.6 0 0) scale(1.376)" />
              <use stroke="#0d96b7" href="#a" transform="rotate(47 0 0) scale(1.47)" />
              <use stroke="#0ca0a7" href="#a" transform="rotate(56.4 0 0) scale(1.564)" />
              <use stroke="#0ba996" href="#a" transform="rotate(65.8 0 0) scale(1.658)" />
              <use stroke="#0ab385" href="#a" transform="rotate(75.2 0 0) scale(1.752)" />
              <use stroke="#08bc75" href="#a" transform="rotate(84.6 0 0) scale(1.846)" />
              <use stroke="#07c664" href="#a" transform="rotate(94 0 0) scale(1.94)" />
              <use stroke="#06cf53" href="#a" transform="rotate(103.4 0 0) scale(2.034)" />
              <use stroke="#05d943" href="#a" transform="rotate(112.8 0 0) scale(2.128)" />
              <use stroke="#04e232" href="#a" transform="rotate(122.2 0 0) scale(2.222)" />
              <use stroke="#02ec21" href="#a" transform="rotate(131.6 0 0) scale(2.316)" />
              <use stroke="#01f511" href="#a" transform="rotate(141 0 0) scale(2.41)" />
              <use stroke="#00FF00" href="#a" transform="rotate(150.4 0 0) scale(2.504)" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>

<style scoped>
.logo-bg-container {
  position: relative;
  z-index: 1;
}

svg {
  width: 100%;
  height: 340px;
  z-index: 1;
  text-align: center;
}

svg defs path {
  animation: 6s alternate animate-stroke; /* infinite */
}

@keyframes animate-stroke {
  0%,
  12% {
    stroke-width: 60%;
    stroke-opacity: 0;
    color: transparent;
  }

  50% {
    stroke-opacity: 0.1;
    background-color: var(--color-border);
  }

  80%,
  100% {
    stroke-width: 0.1%;
    stroke-opacity: 1;
  }
}
</style>
